import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../app/Dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../app/Login/Login.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "login" */ '../app/Login/Logout.vue')
  },
  {
    path: '/password-reset',
    name: 'Password Reset',
    component: () => import(/* webpackChunkName: "login" */ '../app/Login/PasswordReset.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// navigation guard to check for logged in users
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  const firebase = await router.app.$firebase
  const currentUser = firebase.auth().currentUser

  console.log(currentUser)
  if (requiresAuth && !currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
