import Vue from 'vue'
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase'
import axios from 'axios'
// Add the Firebase products that you want to use
import 'firebase/auth'

async function initFirebase() {
  Vue.prototype.$firebase =
    new Promise(async (resolve) => {
      const config = await ( await fetch('/__/firebase/init.json') ).json()
      const app = await firebase.initializeApp(config)

      Vue.prototype.$firebaseConfig = config

      app.auth().onAuthStateChanged((user) => {
        console.log('user state change', user)
        resolve(app)
      })
    })

  return await Vue.prototype.$firebase
}

axios.interceptors.request.use(async (config) => {
  const user = firebase.auth().currentUser
  if (user) {
    const idToken = await user.getIdTokenResult()
    config.headers.Authorization = `Bearer ${ idToken.token }`
  }

  return config
})

initFirebase()

declare module 'vue/types/vue' {
  interface Vue {
    $firebase: Promise<firebase.app.App>;
    $firebaseConfig: {
      projectId: string;
      databaseURL: string;
      storageBucket: string;
      locationId: string;
      apiKey: string;
      authDomain: string;
      messagingSenderId: string;
    };
  }
}
